/* AddDoctor.css */
.AD-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  .TPnote{
    color: red;
    margin-bottom: 2rem;
  }
  
  
  .AD-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--LogoColor);
  }
  
  .AD-input-group {
    margin-bottom: 20px;
  }
  
  .AD-comment-group{
    width: 100%;
    padding: 10px;
    min-height: 4rem;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .AD-comment-About{
    width: 100%;
    padding: 10px;
    min-height: 5rem;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;

  }
  .AD-input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .AD-input-field-dep {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    
  }


  
  .AD-select-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #fff;
  }
  
  .AD-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem; 
  }
  
  .AD-checkbox-label {
    margin-right: 20px;
   
  }
  
  .AD-checkbox {
    margin-right: 5px;
  }
  
  .AD-photo-input {
    margin-bottom: 10px;
  }
  
  .AD-remove-btn,
  .AD-add-btn {
    background:var(--LogoColor);
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .AD-remove-btn:hover,
  .AD-add-btn:hover {
    background: var(--LogoColor);
  }
  
  .AD-time-slots-container {
    margin-bottom: 20px;
  }
  
  .AD-time-slot-label {
    font-weight: bold;
  }
  
  .AD-time-slot-item {
    margin-top: 10px;
  }
  
  .AD-time-label {
    margin-right: 10px;
  }
  
  .AD-time-input {
    margin-right: 10px;
    width: 7rem;
    align-items: center;
    justify-content: center;
  }
  
  .AD-add-btttn {
    background: var(--color2);
    width: 70%;
    color: #fff;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 3rem;
  }
  
  .AD-add-btttn.disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .AD-add-btttn:hover {
    background: var(--color3);
  }
  
  .success-message {
    background-color: var(--LogoColor);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  

  .AD-expertise-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .AD-expertise-item .AD-input-field {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .AD-expertise-item .AD-remove-btn {
  margin-bottom: 1rem;
  }

  .AD-add-btn{
    margin-bottom: 1rem;
  }


  .ADDdocNOTE{
    color: darkblue;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }