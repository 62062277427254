/* DD.css */

.DDD-container {
  width: 96.5%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

.DDD-dashboard {
  display: flex;
  width: 100%;
}

.DDD-doctors-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 18rem;
  border-right: 1px solid #ccc;
  background: var(--LogoColor);
  padding: 0.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 8px;
animation: fadeInLeft 0.5s ease-out; /* Apply fadeInLeft animation */
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.DDD-resett {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  background: var(--LogoColor);
  color: white;
  text-decoration: none;
  white-space: nowrap; /* Prevent content wrapping */
  transition: transform 0.3s, box-shadow 0.3s; /* Add transition for smooth animation */
}

.DDD-resett:hover {
  background: white;
  border-radius: 8px;
  color: black;
  /* transform: translateY(-2px);  */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  */
}

.DDD-doctors-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-left: 20px;
  width: 100%;
}

.DDD-doctor-item {
  flex: 1 1 350px;
  max-width: 350px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}



.DDD-doctor-details {
  display: flex;
  flex-direction: column;
}

.DDD-doctor-name {
  font-weight: 900;
  color: var(--LogoColor);
}

.DDD-doctor-department {
  font-size: 14px;
  color: #555;
}

.DDD-doctor-counter {
  margin-top: 5px;
  font-size: 0.9rem;
  color: red;
}

.DDD-doctor-photo {
  margin-top: 10px;
}

.DDD-photo-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--LogoColor); /* Example border style */
  padding: 2px; /* Adjust this value to create the desired white space */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total size */
  background-color: white; /* Adds a white background inside the circle */
}

.DDD-placeholder-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.DDD-availability-toggle,
.DDD-appointment-toggle {
  margin-top: 7px;
}

.DDD-availability-message,
.DDD-appointment-message {
  font-size: 14px;
  color: #333;
}

@media (max-width: 600px) {
  .DDD-doctor-item {
    flex: 1 1 150px;
    max-width: 150px;
  }
}

.DDD-reset-message {
  position: absolute;
  top: 70%;
  left: 60%;
  transform: translate(-50%, -50%);
  background: var(--LogoColor);
  color: white;
  padding: 1rem;
  border-radius: 20px;
  text-align: center;
  font-size: 1rem;
  z-index: 1000;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.DDD-reset-message.animated {
  opacity: 1;
  animation-duration: 10s;
}

.DDD-no-doctors {
  text-align: center;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
  max-width: 600px;
  animation-delay: 10s; /* Delay the animation by 10 seconds */
  margin: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.DDD-no-doctors h1 {
  color: var(--LogoColor);
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 400;
}

.DDD-add-doctor-link {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background: var(--LogoColor);
  background-size: 200% 200%; /* Double the size to allow animation */
  color: white;
  border-radius: 8px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  animation: gradient66 3s ease infinite; /* Apply animation */
}

@keyframes gradient66 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.DDD-add-doctor-link:hover {
  background: var(--color2);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Center content when there are no doctors */
.DDD-doctors-list:empty + .DDD-no-doctors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
