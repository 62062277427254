/* App.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&family=Nunito:wght@200&family=Open+Sans:wght@300&family=Roboto+Flex:opsz,wght@8..144,100&family=Roboto+Slab:wght@300&family=Urbanist:wght@100&family=Ysabeau+Office:wght@1&display=swap');

/* Root Element Styles */
:root {
  --primary-color: transparent; /* Adjust as needed */
  --secondary-color: transparent; /* Adjust as needed */
  --clr: transparent;
  --global-text-gradient: radial-gradient(circle at 10% 20%, rgb(0, 93, 13) 0%, #03fafa 95%);
  --global-text-gradient-hover: radial-gradient(circle at 10% 20%, rgb(184, 249, 193) 0%, #f1f8f9 90%);
  --text: rgb(27, 26, 26);
  --button: grey;
  --buttonHover: white;
  --LogoColor:darkcyan;
  --color2: darkblue;
  --color3:#4BB543;

}

/*burjeel colour #8c124b
/*burjeelsecond color #b39823

/* Reset styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family:'Urbanist'; */
  font-family:sans-serif;
 }
 

 
/* Body Styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

/* App Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 3rem;
  pointer-events: none;
}

.noise {
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-size: 1.5rem;
}

.App-header {
  background-color: var(--primary-color);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #61dafb;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.LoadingText {
  color: var(--text);
  font-family: 'Urbanist', sans-serif;
  font-size: 0.7rem;
  padding-bottom: 0rem;
}

/* Loading Animation */

.spinner2 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
  margin-top: 1rem;
}

.spinner2 > div {
  width: 18px;
  height: 18px;
  background-color: var(--LogoColor);
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out;
  animation-fill-mode: both;
}

.spinner2 .bounce1 {
  animation-delay: -0.32s;
}

.spinner2 .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
  }
  40% {
    transform: scale(0.5);
  }
}

/* AppBar Styles */
.app-container {
  width: 100%;
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--secondary-color);
}

.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
}

.App-logo {
  display: flex;
  align-items: center;
  height: 3rem;
}

.App-logo img {
  height: 3rem;
  margin-right: 1rem;
}

.AppBartitle {
  font-size: 1.5rem;
  font-family: 'Urbanist', sans-serif;
  color: var(--secondary-color);
}

.krysta {
  font-size: 1.2rem;
  font-family: 'Urbanist', sans-serif;
  color: var(--secondary-color);
  font-weight: bold;
}

/* OfflinePage Styles */
.offline-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--primary-color);
  color: var(--text);
  font-family: 'Urbanist', sans-serif;

}

.offline-page h1 {
  font-size: 2rem;
}

.offline-page p {
  font-size: 1.2rem;
}

.offline-page button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: var(--buttonHover);
  background-color: var(--button);
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.offline-page button:hover {
  background-color: var(--buttonHover);
  color: var(--button);
}
