.QRcontent-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .QRpage-title {
    margin-bottom: 20px;
    font-size: 1.2rem;
    background-color: var(--LogoColor);
    color: white;
    padding: 0.5rem;
    border-radius: 8px;
    font-family: sans-serif;
  }
  
  .error-message {
    color: red;
  }
  
  .qr-code-container {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  