/* GenerateLinkForAPT.css */

/* Container styling */
.APTLINK-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
.APTLINK-heading {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: white;
  background: var(--LogoColor);
  padding: 0.5rem;
}

/* Text styling */
.APTLINK-text {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

/* Email input styling */
.APTLINK-email-input {
  width: calc(100% - 40px);
  padding: 10px;
  margin: 0 auto 20px auto;
  display: block;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Error message styling */
.APTLINK-error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

/* Confirm button styling */
.APTLINK-confirm-button {
  display: block;
  width: 7rem;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background: var(--LogoColor);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto; /* Center the button */
}

.APTLINK-confirm-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.APTLINK-confirm-button:hover:not(:disabled) {
  background: var(--LogoColor);
}

/* Link container styling */
.APTLINK-link-container {
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ccc;
}

/* Link input styling */
.APTLINK-link-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  box-sizing: border-box;
}

/* Button group styling */
.APTLINK-button-group {
  text-align: center;
  margin-top: 20px;
}

/* Copy button styling */
.APTLINK-copy-button {
  display: block;
  width: 16rem;
  margin-bottom: 1rem;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background: var(--LogoColor);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 auto; /* Center the button */
}

.APTLINK-copy-button:hover {
  background: var(--LogoColor);
}

/* Responsive styling */
@media (max-width: 600px) {
  .APTLINK-container {
    padding: 15px;
  }

  .APTLINK-heading {
    font-size: 20px;
  }

  .APTLINK-text {
    font-size: 14px;
  }

  .APTLINK-email-input,
  .APTLINK-link-input {
    width: calc(100% - 30px);
    font-size: 14px;
  }

  .APTLINK-confirm-button,
  .APTLINK-copy-button {
    font-size: 14px;
    padding: 8px;
  }
}
