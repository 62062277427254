/* GenerateLinkForQR.css */

/* Container styling */
.QRLINK-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styling */
  .QRLINK-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--LogoColor);
  }
  
  /* Text styling */
  .QRLINK-text {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
  }

  .QRLINK-info{

margin-top: 1rem;
color: red;
  }
  
  /* Link input styling */
  .QRLINK-link-input {
    width: calc(100% - 40px);
    padding: 10px;
    margin: 0 auto 20px auto;
    display: block;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .QRLINK-container {
      padding: 15px;
    }
  
    .QRLINK-heading {
      font-size: 20px;
    }
  
    .QRLINK-text {
      font-size: 14px;
    }
  
    .QRLINK-link-input {
      width: calc(100% - 30px);
      font-size: 14px;
    }
  }
  
  /* Confirm button styling */
  .QRLINK-confirm-button {
    display: block;
    width: 7rem;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background: var(--LogoColor);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 auto; /* Center the button */
  }
  
  .QRLINK-confirm-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .QRLINK-confirm-button:hover:not(:disabled) {
    background: var(--LogoColor);
  }
  
  /* Copy button styling */
  .QRLINK-copy-button {
    display: block;
    width: 7rem;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background: var(--LogoColor);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 auto; /* Center the button */
  }
  
  .QRLINK-copy-button:hover {
    background: var(--LogoColor);
  }
  