/* TokenReset.css */

/* TokenReset.css */

.TScontainer {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
}

.container1 {
  flex: 3;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.container2 {
  flex: 1;
  padding: 20px;
  color: white;
  background: linear-gradient(89.1deg, rgb(251, 0, 83) 15.2%, rgb(179, 146, 231) 98.5%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-family: 'Urbanist', sans-serif;
}

.container3 {
  flex: 1;
  color: white;
  padding: 20px;
  background: linear-gradient(70.6deg, rgb(172, 30, 255) 0.3%, rgb(65, 35, 251) 55.8%, rgb(35, 251, 224) 105.1%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-family: 'Urbanist', sans-serif;
}

.container4 {
  flex: 1;
  padding: 20px;
  color: white;
  background: radial-gradient(circle at 10% 50.5%, rgb(255, 107, 6) 0%, rgb(255, 1, 107) 90%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-family: 'Urbanist', sans-serif;
}

.container5 {
  flex: 1;
  color: white;
  padding: 20px;
  background: radial-gradient(circle at -0.2% 48.9%, rgb(32, 233, 104) 0%, rgb(13, 167, 236) 99.8%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-family: 'Urbanist', sans-serif;
}

.container6 {
  flex: 1;
  color: white;
  padding: 20px;
  background: linear-gradient(90.9deg, rgb(3, 195, 195) 0.3%, rgb(37, 84, 112) 87.8%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-family: 'Urbanist', sans-serif;
}

/* rest of the CSS remains unchanged */


.resetButton {
  background: darkred;
  color: #fff;
  padding: 0.7rem;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;
}

.resetButton:hover {
  background: red;
}

.doctor-item {
  padding: 1rem;
  margin-left: 1rem;
  background: radial-gradient(circle at 7.1% 15.6%, rgb(22, 160, 133) 0%, rgb(64, 240, 206) 100.3%);
  font-family: 'Urbanist', sans-serif;
  color: white;
}

.doctor-name {
  font-size: 16px;

}

.docNAME {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0.3rem;
}

.docTOKNUM {
  color: black;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
}

.total-patients {
  font-size: 18px;
  margin-bottom: 10px;
}

.numWU,
.numQR,
.numStatusC,
.numStatusD,
.numStatusE {
  color: black;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
}

.reset-message {
  color: #e74c3c;
  font-size: 16px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .TScontainer {
      flex-direction: column;
      align-items: center;
  }

  .container1,
  .container2,
  .container3,
  .container4,
  .container5,
  .container6 {
      width: 100%;
      margin: 10px 0;
  }
}

