.HOME-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color:whitesmoke;
  overflow: hidden;
}

.HOME-line1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  background-color: #fff;
  transition: opacity 0.5s ease-in-out;
}

.HOME-fade-out {
  opacity: 0;
}

.HOME-animated-text {
  font-size: 18px;
  text-align: center;
}

.HOME-animated-text strong {
  color: #007bff;
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply fadeIn animation to HOME-line1 */
.HOME-line1 {
  animation: fadeIn 1s ease-in-out;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .HOME-animated-text {
    font-size: 16px;
  }
}
