.button2 {
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: var(--LogoColor);
  padding: 0.7em 1.7em;
  cursor: pointer;
  font-size: 18px;
  border-radius: 0.5em;
  background: white;
  border: 1px solid var(--LogoColor);
  text-decoration: none;
  margin-bottom: 1rem;

}

.button2:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

.button2:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: var(--LogoColor);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:hover {
  color: #ffffff;
  border: 1px solid var(--LogoColor);
}

.button2:hover:before {
  top: -35%;
  background-color: var(--LogoColor);
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button2:hover:after {
  top: -45%;
  background-color: var(--LogoColor);
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}





.ARPscreen-APWU {
  padding: 20px;
  max-width: 99%;
  margin: 0 auto;
}

.ARPtitleMain {
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 20px;
  color: var(--LogoColor);
}

.ARPadmin-patient-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.ARPtile {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 50%;
  transition: transform 0.3s ease;
  border: 2px solid var(--LogoColor); /* Example border style */
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}

/* .ARPtile:hover {
  transform: translateY(-5px);
} */

.ARPpatient-info p {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #666;
  margin: 10px 0;
}

.ARPpatient-info .field {
  font-weight: 900;
  color: var(--LogoColor);
  flex: 1;
}

.ARPpatient-info .data {
  flex: 2;
  text-align: left;
  color: black;
}

.ARPselect {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.ARPaction-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.ARPaction-buttons button {
  background: var(--LogoColor);
  width: 9rem;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem; /* Increased font size for better visibility */
  cursor: pointer;
  margin: 0.1rem;
  flex-grow: 1; /* Ensures buttons take equal space */
  transition: background-color 0.3s ease;
}

.ARPaction-buttons button:hover {
  background-color: var(--color2);
  color: white;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .ARPtile {
    max-width: 100%;
  }

  .ARPpatient-info p {
    font-size: 1em;
  }

  .ARPSTATU{
    display: none;
  }

  .ARPaction-buttons button {
    font-size: 1rem;
    width: auto;
  }
  
  .search-bar {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

@media (max-width: 600px) {
  .ARPtile {
    max-width: 100%;
    padding: 15px;
  }

  .ARPSTATU{
    display: none;
  }


  .ARPpatient-info p {
    flex-direction: column;
    align-items:flex-start;
  }

  .ARPpatient-info .data {
    text-align: left;
    margin-top: 5px;
  }

  .ARPaction-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .ARPaction-buttons button {
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    margin-bottom: 0.5rem;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

.search-bar {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 400px; /* Ensures a maximum width */
}

.CategoryTitle {
  padding: 10px;
  font-weight: 400;
  margin: 1rem;
  color: white;
  background: var(--color2);
}
