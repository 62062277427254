.TRPscreen-APWU {
    padding: 20px;
    max-width: 99%;
    margin: 0 auto;
  }
  
  .TRPtitleMain {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 20px;
    color: var(--LogoColor);
  }
  
  .TRPadmin-patient-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .TRPtile {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 50%;
    transition: transform 0.3s ease;
    border: 2px solid var(--LogoColor); /* Example border style */
    animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
  }
  
  .TRPpatient-info p {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    color: #666;
    margin: 10px 0;
  }
  
  .TRPpatient-info .field {
    font-weight: 900;
    color: var(--LogoColor);
    flex: 1;
  }
  
  .TRPpatient-info .data {
    flex: 2;
    text-align: left;
    color: black;
  }
  
  .TRPselect {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  
  .TRPaction-button {
    background: var(--LogoColor);
    color: white;
    padding: 0.5rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    margin: 5px;
    width: calc(100% - 10px); /* Subtracting margin from width */
    display: block; /* Ensures buttons are on separate lines */
    
  }
  
  .TRPaction-button:hover {
    background: var(--color2);
  }
  .search-bar {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .ssearch-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 400px;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .TRPtile {
      max-width: 100%;
    }
  
    .TRPpatient-info p {
      font-size: 1em;
    }
  
    .ARPSTATU {
      display: none;
    }
  
    .TRP-button,
    .TRPaction-button {
      font-size: 1rem;
      width: calc(100% - 10px);
    }
    .ssearch-bar input {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-width: 400px;
    }
  }
  
  @media (max-width: 600px) {
    .TRPtile {
      max-width: 100%;
      padding: 15px;
    }
  
    .ARPSTATU {
      display: none;
    }
  
    .TRPpatient-info p {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .TRPpatient-info .data {
      text-align: left;
      margin-top: 5px;
    }
  
    .TRP-button,
    .TRPaction-button {
      width: calc(100% - 10px);
      font-size: 0.9rem;
      padding: 12px 20px;
      margin-bottom: 0.5rem;
    }
    .ssearch-bar input {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-width: 400px;
    }
  }

  .delete-button {
    background-color: #ff4d4d;
    color: white;
  }
  
  .delete-button:hover {
    background-color: #ff1a1a;
  }