/* HospitalsData.css */

.Phospitals-data {
  max-width: 50%;
  margin: 0 auto;
  padding: 20px;
}

.logoLocation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center; /* Center items horizontally */
}

.update-message {
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 4px;
  margin-bottom: 20px;
}

.Phospital-list {
  list-style-type: none;
  padding: 0;
  background-color: white;
}

.Phospital-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
}

.Phospital-item:hover {
  background-color: white;
}

.logo-container {
  display: flex;
  flex-direction: column; /* Stack logo and input vertically on smaller screens */
  align-items: center;
  margin-bottom: 10px;
  background-color: whitesmoke;
  padding: 1rem;
}

.location-container {
  display: flex;
  flex-direction: column; /* Stack location and button vertically on smaller screens */
  align-items: center;
  margin-bottom: 10px;
  background-color: whitesmoke;
  padding: 1rem;
}

.hospital-logo {
  width: 15rem;
  height: auto;
  margin-bottom: 10px; /* Add some spacing between logo and input */
}

.LUnote{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  background: var(--LogoColor);
  color: white;
  padding: 0.5rem;
}

.logo-input {
  margin-top: 10px; /* Add some spacing between logo and input */
}

.fetch-coordinates-btn {
  margin-top: 10px; /* Add some spacing between location and button */
  margin-bottom: 0px;
  margin-left: 0; /* Remove left margin */
  background: var(--LogoColor);
}

.fetch-coordinates-btn:hover {
  background: var(--color2);
}

.update-form {
  margin-top: 20px;
}

.form-input {
  margin-bottom: 10px;
  padding: 5px;
}

.update-btn {
  margin-top: 10px;
}

.edit-btn {
  background: var(--LogoColor);
  margin-right: 2rem;
}

.save-btn{ background: var(--LogoColor);
  margin-right: 2rem;}
  .save-btn:hover{ background: var(--LogoColor);
    margin-right: 2rem;}

.edit-btn:hover {
  background: var(--LogoColor);
}

.PFL-cancel-btn {
  background: var(--LogoColor);
}

.PFL-cancel-btn:hover {
  background: var(--LogoColor);
}

/* Styles for smaller screens */
@media (max-width: 768px) {
  .Phospital-item {
    flex-direction: column;
    /* Adjust layout for smaller screens */
  }

  .logo-container {
    flex-direction: column; /* Stack logo and input vertically */
  }

  .location-container {
    flex-direction: column; /* Stack location and button vertically */
  }

  .hospital-logo {
    max-width: 100%;
    /* Adjust logo size for smaller screens */
  }

  .edit-btn,
  .PFL-cancel-btn {
    margin-right: 0; /* Remove right margin for buttons */
    margin-bottom: 10px; /* Add some spacing between buttons */
  }
}


.platform-fee {
  display: block;
  font-size: 1em;
  color: white;
  background: var(--LogoColor);
  padding: 0.5rem;
  border-radius: 8px;
  color: white;
  animation: gradientAnimation 3s ease infinite; /* Apply gradient animation */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.krysta-care-commission {
  display: block;
  font-size: 1em;
  color: white;
  background: var(--LogoColor);
  padding: 0.5rem;
  border-radius: 8px;
  color: white;
  animation: gradientAnimation 3s ease infinite; /* Apply gradient animation */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.noteKCC{
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  text-align: center;
  color: white;
  font-family: serif;
}



