/* Container */
.screenP-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Form Container */
.form-container {
  display: flex;
  flex-direction: column;
}

/* Inputs and Selects */
.name-input-field,
.dept-input-field {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}
/* Inputs and Selects */
.name-input-field,
.dept-input-field {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.name-input-field::placeholder,
.dept-input-field::placeholder {
  color: #999;
}

.name-input-field:focus,
.dept-input-field:focus {
  border-color: var(--LogoColor);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Button */
.token-proceed-button,
.checkk-status-button {
  background: var(--LogoColor);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.token-proceed-button:disabled,
.checkk-status-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.token-proceed-button:hover:not(:disabled),
.checkk-status-button:hover:not(:disabled) {
  background: var(--color2);
}

/* Patient Details */
.patient-details {
  margin-bottom: 20px;
}

.patient-details p {
  margin: 10px 0;
}

.SP-P1-info {
  margin-bottom: 10px;
}

.SP-P1-label {
  font-weight: bold;
  color: var(--LogoColor);
}

/* Messages */
.message {
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
}

.message-container {
  margin-top: 20px;
}

.thank-you,
.DRCONSUL,
.consultation-message{
  color: var(--color2);
}

.message.status-A {
  background-color: var(--LogoColor);
  /* border: 1px solid #ccc; */
}

.message.status-B {
  background-color: var(--LogoColor);
  /* border: 1px solid #ffc107; */
}

.message.status-C {
  background-color: var(--LogoColor);
  /* border: 1px solid #00acc1; */
}

.now-servingNOW-token{
  color: white;
  margin-top: 2rem;
}

.message.status-D {
  background-color: var(--LogoColor);
}

.messageConsulted.status-E {
  background-color: var(--LogoColor);
}

.message.status-F {
  background-color: #ffebee;
  border: 1px solid #f44336;
}

/* Token Number, Doctor Room, and Assigned Doctor */
.token-number,
.doctor-room,
.doctor-assigned {
  background-color: var(--LogoColor);
  font-weight: bold;
  display: block;
  margin: 5px 0;
  color: white;
  padding: 0.5rem;
}

.wait-message {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.now-serving-token {
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: var(--LogoColor);
}

/* Already Text */
.alreadytext {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

/* Appointment Button */
.button-APTVW {
  display: inline-block;
  background-color: var(--LogoColor);
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 10px;
}

.button-APTVW:hover {
  background-color: var(--color2);
}

/* Responsive */
@media (max-width: 768px) {
  .screenP-container {
    margin: 20px;
    padding: 20px;
  }

  .token-proceed-button,
  .checkk-status-button {
    padding: 10px 15px;
  }
}
