/* Container for the WhatsApp-style chatbot */
.CBwhatsapp-container {
  max-width: 500px;
  margin: 0 auto;
  border: 0px solid #e5e5e5;
  border-radius: 0px;
  overflow: hidden;
  margin-top: 0rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* Header styling similar to WhatsApp */
.CBchat-header {
  background-color: #075E54;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
}

/* Profile image in the chat header */
.CBcontact-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Contact info in the chat header */
.CBcontact-info h2 {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
}

.CBcontact-info p {
  margin: 0;
  font-size: 13px;
  opacity: 0.9;
  text-align: left;
}

/* Chat messages container */
.CBchat-messages {
  height: calc(75vh - 60px); /* Adjust 60px based on your header height */
  overflow-y: auto;
  padding: 10px;
  background-color: #e5ddd5;
}

/* Message bubble styling */
.CBmessage {
  max-width: 65%;
  margin-bottom: 8px;
  padding: 6px 7px 8px 9px;
  border-radius: 7.5px;
  position: relative;
  font-size: 14.2px;
  line-height: 19px;
}

/* Received message bubble */
.CBreceived {
  background-color: #ffffff;
  text-align: left;
  margin-left: 10px;
  color: black;
  border: 1px solid #e5e5e5;
  border-top-left-radius: 0;
}

/* Sent message bubble */
.CBsent {
  background-color: #dcf8c6;
  margin-left: auto;
  text-align: right;
  border-top-right-radius: 0;
}
.CBinput::placeholder {
  color: #999;
  font-size: 14.2px;
}


/* Error message styling */
.CBerror {
  color: red;
}

/* Input fields for the chatbot */
.CBinput, select {
  width: calc(100% - 20px);
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: 14px;
}

/* Button styling similar to WhatsApp */
button {
  background: #128c7e;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

button:hover {
  background: #0b7a68;
}

/* Profile icon with a gradient background */
.CBdefault-bot-color {
  background: linear-gradient(117deg, #017373 8% 6%, #0ff 80%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Minimal style for the timeslot button */
.timeslot-period .timeslot-options button {
  background-color: transparent;
  border: 1px solid #008B8B; /* Dark cyan border */
  padding: 6px 10px;
  margin: 5px;
  border-radius: 15px; /* Rounded edges */
  cursor: pointer;
  font-size: 14px;
  color: #008B8B; /* Dark cyan text color */
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* Style when a timeslot is selected */
.timeslot-period .timeslot-options button.selected {
  background: #008B8B; /* Dark cyan background for selected */
  color: white; /* White text color */
}

/* Hover effect for timeslot buttons */
.timeslot-period .timeslot-options button:hover {
  background: #008B8B; /* Light gray on hover */
  color: white;
}


.CBrestart-button-container {
  text-align: center;
  margin-top: 20px;
  
}

.CBrestart-button {
  background-color: #128c7e;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.CBrestart-button:hover {
  background-color: #0b7a68;
}

.CBconfirm-restart {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 300px;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}

.CBconfirm-restart p {
  margin-bottom: 15px;
  font-size: 16px;
}

.CBconfirm-button, .CBcancel-button {
  background-color: #128c7e;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.CBconfirm-button:hover, .CBcancel-button:hover {
  background-color: #0b7a68;
}

.CBcancel-button {
  background-color: #e5e5e5;
  color: black;
}

.CBcancel-button:hover {
  background-color: #cccccc;
}


.dateInput {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  font-weight: 500; /* Optional: makes the text slightly bolder */
}

.CBmessage input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color:  #128c7e;
}

.CBmessage input[type="date"]:focus {
  border-color: #101111;
  outline: none;
  box-shadow: 0 0 5px rgba(21, 22, 22, 0.5);
}

.timeslot-period {
  margin-bottom: 20px;
}

.timeslot-period h3 {
  font-size: 14.2px;
  margin-bottom: 10px;
  color: #008B8B;
}


