.DV-screen-d-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}

.DV-no-patients {
  text-align: center;
  font-size: 18px;
  color: darkred;
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #ddd;
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}


.DV-screen-d {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.current-date-time {
  font-size: 1rem;
  color:black;
  /* background: var(--color2); */
  text-align: right;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

.DV-dHead {
  color:white;
  background: var(--LogoColor);
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.DV-logout-btn, select, .DV-doctor-availability button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  margin-top: 15px;
}

.DV-logout-btn {
  background: var(--color2);
  color: white;
  width: 30%;
  margin-bottom: 2rem;
}

.DV-logout-btn:hover {
  background: #ef4444;
  color: white;
  width: 30%;
}

select {
  border: 2px solid var(--LogoColor);
  background-color: white;
}

.DV-doctor-info, .DV-doctor-availability, .DV-patient-section {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
}


.DV-doctor-availability, .DV-patient-section {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.DV-doctor-info {
  display: flex;
  align-items: center;
  background: white;
  margin-top: 0.3rem;
}

.DV-doctor-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 2px solid var(--LogoColor);
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
  padding: 2px; /* Adjust this value to create the desired white space */
box-sizing: border-box; /* Ensures padding and border are included in the element's total size */
background-color: white; /* Adds a white background inside the circle */
}


.DV-no-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid var(--color2);
  animation: fadeInUp 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #888;
  font-size: 14px;
  text-align: center;
}

.DV-doctor-name, .DV-doctor-department {
  margin: 0;
  color: var(--LogoColor);
  text-align: left; /* Added this line */
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}

.DV-doctor-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.DV-doctor-department {
  font-size: 1rem;
  color: var(--LogoColor);
}

.DV-doctor-availability {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DV-doctor-availability h3 {
  margin: 0;
  color: #1e293b;
  font-size: 1.1rem;
  font-weight: 600;
}

.DV-toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.DV-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.DV-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.DV-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .DV-slider {
  background-color: var(--LogoColor);
}

input:checked + .DV-slider:before {
  transform: translateX(26px);
}

.DV-patient-header, .DV-patient-row {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr;
  padding: 12px;
  align-items: center;
  font-size: 1rem;
}

.DV-patient-header {
  background-color: var(--LogoColor);
  color: white;
  font-weight: 500;
}

.DV-patient-row {
  border-bottom: 1px solid #e2e8f0;
  animation: fadeInUp 0.5s ease-out; /* Apply fadeInUp animation */
}

.DV-patient-row:last-child {
  border-bottom: none;
}

.DV-patient-row button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 5px; /* Added margin for spacing */
}

.DV-patient-row .DV-buttonNN {
  background-color: #ef4444;
}

.negative-time {
  background-color: #fee2e2;
}

@media (max-width: 992px) {
  .DV-screen-d {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .DV-screen-d {
    max-width: 95%;
    padding: 15px;
  }
  
  .DV-doctor-info {
    flex-direction: column;
    text-align: center;
  }
  
  .DV-doctor-photo {
    margin-right: 0;
    margin-bottom: 15px;
  }
  
  .DV-doctor-availability {
    flex-direction: column;
    gap: 15px;
  }
  
  .DV-patient-header, .DV-patient-row {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .DV-patient-header span:nth-child(2),
  .DV-patient-row span:nth-child(2),
  .DV-patient-header span:nth-child(3),
  .DV-patient-row span:nth-child(3) {
    display: block;
  }

  .DV-patient-row button {
    margin-top: 5px; /* Add spacing between buttons on smaller screens */
  }
}

@media (max-width: 480px) {
  .DV-screen-d {
    padding: 10px;
  }
  
  .DV-dHead {
    font-size: 1.3rem;
  }
  
  .DV-doctor-photo {
    width: 60px;
    height: 60px;
  }
  
  .DV-doctor-name {
    font-size: 1.1rem;
  }
  
  .DV-doctor-department {
    font-size: 0.9rem;
  }
  
  .DV-patient-header, .DV-patient-row {
    font-size: 0.9rem;
    padding: 10px;
  }
  
  .DV-patient-row button {
    font-size: 0.8rem;
    padding: 6px 10px;
    margin-top: 5px; /* Add spacing between buttons on smaller screens */
  }
}

.DVbuttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.DV-button,
.DV-buttonNN {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 7rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.DV-buttonn {
  background: darkblue;
  color: white;
}

.DV-button:hover {
  background: var(--LogoColor);
}

.DV-buttonNN {
  background: darkred;
  color: white;
}

.DV-buttonNN:hover {
  background: red;
}

@media (max-width: 768px) {
  .DV-button,
  .DV-buttonNN {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .DV-button,
  .DV-buttonNN {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

.P4H-UPAlink {
  text-decoration: none;
  background:var(--LogoColor);
  color: white;
  padding: 0.3rem;
  display: inline-block;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease, background-size 0.3s ease;
}

.P4H-UPAlink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background:darkblue;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.P4H-UPAlink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.P4H-UPAlink:hover {
  color: white; /* Define this variable or use a specific color */
}


