/* Container */
.ST-screen-container {
  max-width: 900px;
  margin: 60px auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.noteVAP{
  color: red;
}

/* Header */
.ST-main-heading {
  color: var(--LogoColor);
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

/* Doctor Selection */
.ST-doctor-selection h2,
.ST-video-selection h2 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  color: #333;
  border-bottom: 2px solid var(--LogoColor);
  padding-bottom: 5px;
}

.ST-doctor-checkbox,
.ST-video-link-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background: #e8f0fe;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.ST-doctor-checkbox:hover,
.ST-video-link-checkbox:hover {
  transform: scale(1.02);
}

.ST-doctor-checkbox input,
.ST-video-link-checkbox input {
  margin-right: 15px;
  transform: scale(1.2);
}

.ST-doctor-checkbox label,
.ST-video-link-checkbox label {
  font-size: 1rem;
  color: #333;
}

/* Buttons Container */
.ST-buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}

.ST-buttons-container button,
.ST-buttons-container a button {
  background: var(--LogoColor);
  color: #ffffff;
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.ST-buttons-container button:hover,
.ST-buttons-container a button:hover {
  background: #0f59c4;
}


/* Responsive */
@media (max-width: 768px) {
  .ST-screen-container {
    margin: 20px;
    padding: 20px;
  }

  .ST-main-heading {
    font-size: 1.5rem;
  }

  .ST-buttons-container {
    flex-direction: column;
    gap: 10px;
  }

  .ST-buttons-container button,
  .ST-buttons-container a button {
    padding: 10px 20px;
  }
}
