/* Container */
.screen4de {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: auto;
  }
  
  /* Form Container */
  .form-container {
    display: flex;
    flex-direction: column;
 
    
  }
  
  /* Inputs and Selects */
  .input-field {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: var(--LogoColor);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .input-field::placeholder {
    color: #999;
  }
  
  .input-field:focus {
    border-color: var(--LogoColor);
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
  }
  
  /* Buttons */
  .token-button {
    background: var(--LogoColor);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .token-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .token-button:hover:not(:disabled) {
    background: var(--color2);
  }
  
  /* Messages */
  .AvailDays3 {
    background: var(--LogoColor);
    padding: 1rem;
    margin-bottom: 2rem;
    color: white;
    text-decoration: none;
  }
  
  .linkStyle2 {
    color: white;
    text-decoration: underline;
  }
  
  .message {
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    color: white;
  }
  
  /* Success message styles */
  .token-button.processing {
    background: #ccc;
  }
  
  .token-button.success {
    background:var(--color3);
    color:  white;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .screen4de {
      margin: 20px;
      padding: 20px;
    }
  
    .token-button {
      padding: 10px 15px;
    }
  }
  
  /* Additional Styles */
  h3.CThead {
    color: var(--LogoColor);
    margin-bottom: 20px;
  }
  
  /* Tick mark animation */
  .tick-mark {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
    position: relative;
  }
  
  .tick-mark svg {
    width: 100%;
    height: 100%;
  }
  
  .tick-mark circle {
    fill: var(--LogoColor);
    stroke-dasharray: 166;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: whitesmoke;
    animation: strokeCircle 2s infinite;
  }
  
  .tick-mark path {
    fill: none;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2;
    stroke: white;
    animation: strokePath 2s infinite;
  }
  
  @keyframes strokeCircle {
    0%, 100% {
      stroke-dashoffset: 166;
    }
    50% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes strokePath {
    0%, 100% {
      stroke-dashoffset: 48;
    }
    50% {
      stroke-dashoffset: 0;
    }
  }
  
  /* Tooltip */
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background: var(--LogoColor);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the button */
    left: 50%;
    margin-left: -100px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  