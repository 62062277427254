.dashboard {
    padding: 16px;
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .dashboard__title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
    color: #343a40;
  }
  
  .dashboard__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  
  .dashboard__button {
    background: var(--LogoColor);
    border-radius: 8px;
    padding: 16px;
    text-decoration: none;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .dashboard__button:active {
    background-color: #e9ecef;
  }
  
  .dashboard__button-icon {
    display: none;
  }
  
  @media (max-width: 320px) {
    .dashboard__grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (min-width: 768px) {
    .dashboard {
      padding: 24px;
    }
  
    .dashboard__title {
      font-size: 28px;
    }
  
    .dashboard__grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  
    .dashboard__button {
      font-size: 16px;
    }
  }


  .notice {
    background-color: #f9f9f9; /* Light grey background */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Padding inside the box */
    font-family: Arial, sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    color: #333; /* Dark grey text color */
    text-align: left; /* Centered text */
    margin: 20px auto; /* Center the box horizontally */
    max-width: 600px; /* Maximum width of the box */
}
