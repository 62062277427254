

/* Container */
.Alogin-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Form */
.Alogin-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title */
.LoginFormTitle {
  color: var(--LogoColor);  
  margin-bottom: 20px;
  font-size: 1.3rem;
}

/* Inputs */
.Alogin-container input {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.Alogin-container input:focus {
  border-color: var(--LogoColor);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.Alogin-container :placeholder-shown{
  font-size: 1rem;
}

/* Button */
.Alogin-container button {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  background: var(--LogoColor);
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Alogin-container button:disabled {
  background: var(--LogoColor);         /* Set background color to white */
  cursor: not-allowed;       /* Change cursor to indicate non-clickable button */
}

.Alogin-container button:hover:not(:disabled) {
  background: var(--LogoColor);
}


/* Reset Password Modal */
.reset-password-modal {
 margin-top: 2rem;
}

/* Signup Link */
.Asignup-link {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: var(--LogoColor);
  transition: color 0.3s;
  margin-bottom: 2rem;
}

.Asignup-link:hover {
  color: black;
  text-decoration: none;
}

/* Responsive */
@media (max-width: 768px) {
  .Alogin-container {
    margin: 20px;
    padding: 20px;
  }
}