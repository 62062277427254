/* Main.css */

.mobile-message {
    padding: 20px;
    background-color: #ffcccc;
    text-align: center;
  }

  /* .Main-container{
    background: whitesmoke;
  } */