

.UPAPTV-screen-d {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.UPAPTV-heading {
  color: var(--LogoColor);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.UPAPTV-back-button {
  display: inline-block;
  background: var(--LogoColor);
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 50%;
  transition: background-color 0.3s ease;
}

.UPAPTV-back-button:hover {
  background: var(--color2);
}

.UPAPTV-title {
  color: var(--LogoColor);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.UPAPTV-appointment-list {
  display: grid;
  gap: 1rem;
}

.UPAPTV-appointment-item {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.UPAPTV-appointment-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.UPAPTV-appointment-time {
  font-size: 1.25rem;
  color: var(--LogoColor);
  margin: 0;
}

.UPAPTV-no-appointments-container {
  text-align: center;
}

.UPAPTV-no-appointments {
  color: #888;
}

.UPAPTV-check-again {
  background: var(--color2);
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.UPAPTV-check-again:hover {
  background: var(--color3);
}

.UPAPTV-no-doctor {
  text-align: center;
  color: #888;
  
}

.UPAPTV-checking {
  text-align: center;
  color: var(--LogoColor);
  font-size: 1rem;
  margin-top: 2rem;
}

.UPAPTV-last-check {
  text-align: center;
  color: #888;
  font-size: 0.9rem;
  margin-top: 2rem;
}

@media (max-width: 600px) {
  .UPAPTV-screen-d {
    padding: 1rem;
  }

  .UPAPTV-heading {
    font-size: 2rem;
  }

  .UPAPTV-title {
    font-size: 1.25rem;
  }

  .UPAPTV-appointment-time {
    font-size: 1rem;
  }
}