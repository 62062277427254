/* General Styles */
html {
  scroll-behavior: smooth;
}

/* Keyframes */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 121, 168, 0.7);
  }
  70% {
    box-shadow: 0 0 0 7px rgba(253, 121, 168, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(253, 121, 168, 0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* BLL Billing Styles */
.BLL-billing {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  background-color: white;
  color: black;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.BLL-content {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.3s ease;
}

.BLL-title {
  font-size: 1.3rem;
  color: white;
  background: var(--LogoColor);
  margin-bottom: 2rem;
  padding: 0.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  animation: fadeInDown 0.5s ease-out;
}

.BLL-no-payments {
  font-size: 1rem;
  color: darkblue;
  opacity: 0.7;
}

.BLL-month {
  margin-bottom: 1.5rem;
  animation: fadeIn 0.5s ease-out;
}

.BLL-month-title {
  font-size: 1.3rem;
  color: darkblue;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.BLL-table-wrapper {
  overflow-x: auto;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.BLL-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.BLL-table th,
.BLL-table td {
  padding: 0.3rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.BLL-table th {
  background-color: var(--LogoColor);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.6rem;
}

.BLL-table tr:nth-child(even) {
  background-color: rgba(108, 92, 231, 0.05);
}

.BLL-table tr {
  transition: transform 0.2s ease;
}

.BLL-grand-total {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  border-radius: 15px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  color: var(--LogoColor);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  animation: slideIn 0.5s ease-out;
}

.BLL-total-title {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.BLL-total-amount {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: darkblue;
}

.BLL-pay-button {
  background: var(--LogoColor);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 3px 10px rgba(253, 121, 168, 0.4);
  animation: pulse 2s infinite;
}

.BLL-pay-button:hover{
  background: var(--color2);

}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .BLL-billing {
    padding: 0.5rem;
  }

  .BLL-content {
    padding: 1rem;
  }

  .BLL-title {
    font-size: 1.5rem;
  }

  .BLL-month-title {
    font-size: 1.2rem;
  }

  .BLL-table th,
  .BLL-table td {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .BLL-total-title {
    font-size: 1rem;
  }

  .BLL-total-amount {
    font-size: 1.4rem;
  }

  .BLL-pay-button {
    width: 100%;
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
    margin-bottom: 3rem;
  }
}

/* Coming Soon Styles */
.coming-soon {
  display: inline-block;
  font-size: 0.5rem;
  padding: 0.3rem;
  margin-top: 0.3rem;
  background: linear-gradient(45deg, #6a11cb, #2575fc, #6a11cb);
  background-size: 200% 200%;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: gradient 3s ease infinite;
}

.coming-soon:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
