.BBsearch-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.BBinput{
  height: 2rem;
  width: 7rem;
}

.BBsearch-bar p {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
}

.BBclear-button {
  padding: 10px 20px;
  background: var(--LogoColor);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.BBclear-button:hover {
  background:darkblue;
}
