/* Container */
.WU-screenP-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Form Container */
.form-container {
  display: flex;
  flex-direction: column;
  
}

/* Inputs and Selects */
.input-field {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-field::placeholder {
  color: #999;
}

.input-field:focus {
  border-color: var(--LogoColor);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Buttons */
.proceed-button {
  background: var(--LogoColor);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.proceed-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.proceed-button:hover:not(:disabled) {
  background: #0056b3; /* Darker shade of blue on hover */
}

.AvailDays2{

background: var(--LogoColor);
padding: 0.1rem;
margin-bottom: 0rem;
color: white;
}

.AvailDays3{

  background: var(--LogoColor);
  padding: 1rem;
  margin-bottom: 2rem;
  color: white;
  text-decoration: none;
  }

  .linkStyle2{

  }

.noteDOWN{
  margin-top: 3rem;
}

.check-button {
  background: var(--LogoColor);
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.check-button2 {
  background: var(--LogoColor);
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 2rem;
  transition: background-color 0.3s;
}

.check-button:hover {
  background: var(--LogoColor);
}

.check-button2:hover {
  background: var(--LogoColor);
}
/* Messages */
.message {
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  color: white;
}

.status-A,
.status-B,
.status-C,
.status-D,
.status-E {
  background-color: var(--LogoColor);
}

.status-F {
  background-color: #ffebee;
  border: 1px solid #f44336;
}

/* Responsive */
@media (max-width: 768px) {
  .WU-screenP-container {
    margin: 20px;
    padding: 20px;
  }

  .proceed-button {
    padding: 10px 15px;
  }
}

/* Button Style */
.button-style {
  background-color: #ffffff;
  color: var(--LogoColor);
  border: 2px solid var(--LogoColor);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* .button-style:hover {
  background-color: var(--LogoColor);
  color: #ffffff;
  border-color: var(--LogoColor);
} */

/* Additional Styles */
.titleP {
  color: var(--LogoColor);
  margin-bottom: 20px;
}

.noteAP {
  margin-top: 1rem;
  color: var(--LogoColor);
}

.AvailDays {
  background-color: var(--LogoColor);
  padding: 0.5rem;
  color: white;
}

/* Mobile Number Lookup */
.mobile-number-lookup {
  margin-top: 1rem;
}

/* Already Text */
.alreadytext {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}


.tick-mark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  margin: 10px auto;
  position: relative;
}

.tick-mark svg {
  width: 100%;
  height: 100%;
}

.tick-mark circle {
  fill: var(--LogoColor);
  stroke-dasharray: 166;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: whitesmoke;
  animation: strokeCircle 2s infinite;
}

.tick-mark path {
  fill: none;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke-width: 2;
  stroke: white;
  animation: strokePath 2s infinite;
}

@keyframes strokeCircle {
  0%, 100% {
    stroke-dashoffset: 166;
  }
  50% {
    stroke-dashoffset: 0;
  }
}

@keyframes strokePath {
  0%, 100% {
    stroke-dashoffset: 48;
  }
  50% {
    stroke-dashoffset: 0;
  }
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background:var(--LogoColor);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.aptitle{
  margin: 1rem;
}