
   
   
   /* Container */
   .signup-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
   }
   
   /* Header */
   .signup-header {
    color: var(--LogoColor);  
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 700;
   }
   
   /* Form */
   .signup-form {
    display: flex;
    flex-direction: column;
   }

   .photoUpload{
    display: flex;
    margin-bottom: 0.5rem;
    background: var(--LogoColor);
    padding: 0.5rem;
    color: white;

   }
   
   /* Inputs and Selects */
   .signup-input,
   .signup-select {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: var(--LogoColor);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
   }
   
   .signup-input::placeholder,
   .signup-select::placeholder {
    color: #999;
   }
   
   .signup-input:focus,
   .signup-select:focus {
    border-color: var(--LogoColor);
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
   }
   
   /* Coordinates */
   .signup-coordinates {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
   }
   
   .signup-input-coordinates {
    flex: 1;
    margin-right: 10px;
   }

   .fetchCord{
    align-items: center;
    justify-content: center;
    margin-top: -0.6rem;
    height: 2.5rem;
    background: var(--LogoColor);
   }
   .fetchCord{
    background: var(--LogoColor);
   }
   .fetchCord:active {
    background: var(--LogoColor); /* Darker shade of blue when pressed */
  }

  .fetchCord:hover {
    background: darkblue;
  }
   /* Button */
   .signup-button {
    background: darkblue;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 2rem;
    transition: background-color 0.3s;
   }
   
   .signup-button:disabled {
    background: var(--LogoColor);
    cursor: not-allowed;
   }
   
   .signup-button:hover:not(:disabled) {
    background: var(--LogoColor);
   }
   
   /* Responsive */
   @media (max-width: 768px) {
    .signup-container {
      margin: 20px;
      padding: 20px;
    }
   
    .signup-header {
      font-size: 24px;
    }
   
    .signup-button {
      padding: 10px 15px;
    }
   }