/* src/components/OfflinePage/OfflinePage.css */
.offline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .offline-content {
    text-align: center;
  }
  
  .offline-content h1 {
    color: #333;
  }
  
  .offline-content p {
    color: #666;
  }
  