/* AAVD Wow CSS */
.AAVDscreen-APWU {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .AAVDSTATU {
    margin-bottom: 20px;
  }
  
  .AAVDtitleMain {
    font-size: 24px;
    color: var(--LogoColor);
    margin-bottom: 10px;
  }
  
  .AAVDssearch-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .AAVDssearch-bar input {
    padding: 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .AAVDfilter-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .AAVDfilter-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }
  
  .AAVDfilter-bar label {
    margin-right: 10px;
  }
  
  .AAVDfilter-bar select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .AAVDtable-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .AAVDpatient-table {
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    
  }
  
  .AAVDpatient-table th,
  .AAVDpatient-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  .AAVDpatient-table th {
    background-color: #f2f2f2;
    background: var(--LogoColor);
    color: white;
  }
  
  .AAVDpatient-table tr:nth-child(even) {
    background-color: wheat;
  }
  