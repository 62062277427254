.TOK-details-container {
  display: flex;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  margin-top: 0.2rem;
  
}

.TOKleft-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  
}

.TOKright-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  overflow-y: auto;
}

.TOKdoctor-container {
  margin-bottom: 20px;
}

.TOKdoctor-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding: 0.2rem;
  color: white;
  background: var(--LogoColor);
  /* border-radius: 8px; */
  font-family: sans-serif;
}

.TOKpatient-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background: white;
}

.TOKpatient-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background: var(--color2);
  
}

.TOKpatient-item:last-child {
  border-bottom: none;
}


.highlight {
  background-color: yellow;
}

.patient-info {
  display: block;
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-family: sans-serif;
  color: white;
}

.TOKdocTok,
.TOKroomInfo {
  display: inline-block;
  margin-right: 10px;
  font-size: 1.5rem;
  font-family: sans-serif;
  color: white;
}

.TOKvideo-container {
  width: 100%;
  max-width: 560px;
  margin-bottom: 1rem;
}

.TOKtokenNameF{
  font-family: sans-serif;
}

.TOKvideo-container iframe {
  width: 100%;
  height: 315px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .TOK-details-container {
    flex-direction: column;
  }

  .TOKleft-container,
  .TOKright-container {
    width: 100%;
    padding: 10px;
  }

  .TOKvideo-container iframe {
    height: 210px;
  }
}


.patient-infoN{

  display: inline-block;
  margin-right: 10px;
  font-size: 2rem;
  font-family: sans-serif;
  color: var(--color2);
  background: white;
  font-weight: 900;
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.TOKno-tokens {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.TOKno-tokens p {
  font-size: 24px;
  font-weight: bold;
  color: #666;
}