/* Stat.css */

.STAT-patientsContainer {
  width: 96%; /* Adjusted width */
  margin: 0 auto;
  padding: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  overflow-x: auto; /* Apply horizontal scroll if content overflows */
  display: flex;
  flex-direction: row;
  align-items: center;
  background: whitesmoke;
  border-radius: 8px;
  gap: 20px; /* Add gap between cards */
}

.STAT-patientCard {
  flex: 1 0 200px; /* Ensures the flex item takes up available space */
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: transform 0.3s, box-shadow 0.3s;
}

.STAT-cardLink {
  text-decoration: none;
  color: inherit;
  display: contents;
}

.STAT-patientCard h4 {
  font-weight: 400;
}

.STAT-patientCount {
  font-size: 1rem;
  margin-top: 10px;
}

/* Card hover animation */
.STAT-patientCard:hover {
  /* transform: translateY(-10px);  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

/* Initial card animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.STAT-patientCard {
  animation: fadeInUp 0.5s ease-out;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .STAT-patientCard {
    flex: 1 0 100%; /* Adjust as needed for smaller screens */
  }
}


.STAT-cardLink {
  text-decoration: none;
  color: inherit;
  display: contents;
}
.STAT-patientCard h4 {
  font-weight:400;
}

.STAT-patientCount {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .STAT-patientCard {
    flex: 1 0 100%; /* Adjust as needed for smaller screens */
  }
}


/* Stat.css */

.STAT-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.STAT-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.STAT-header h1 {
  font-size: 24px;
  margin: 0;
}

.STAT-resetButton {
  border: none;
  font-weight: 900;
  font-family: 'Urbanist', sans-serif;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  background: var(--LogoColor);
  color: white;
}

.STAT-resetButton:hover {
  background: white;
  color: black;
}

.STAT-patientsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.STAT-patientCard {
  flex: 1 0 200px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.STAT-patientCard h4 {
  font-size: 18px;
  margin-top: 0;
}

.STAT-patientCount {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 0;
  background-color: var(--LogoColor);
  color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.STAT-resetMessage {
  margin-top: 20px;
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .STAT-patientCard {
    flex: 1 0 100%;
  }
}

/* Additional CSS for appointments table */
.AAV-Admin-appointments-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.AAV-Admin-appointments-table-header {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ddd;
}

.AAV-Admin-appointments-table-data {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.AAV-Admin-appointments-table-row:hover {
  background-color: #f9f9f9;
}
