/* Container */
.VLYTB-videoLinkManager {
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.VLYTB-videoLinkManager h2 {
  color: var(--LogoColor);
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}

/* Logout Button */
.VLYTB-logout-btn {
  background: var(--LogoColor);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.VLYTB-logout-btn:hover {
  background: #0056b3;
}

/* Add Link Container */
.VLYTB-add-link-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.VLYTB-add-link-container input {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.VLYTB-add-link-container input::placeholder {
  color: #999;
}

.VLYTB-add-link-container input:focus {
  border-color: var(--LogoColor);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.VLYTB-add-link-container button {
  background: var(--LogoColor);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.VLYTB-add-link-container button:hover {
  background: #0056b3;
}

/* Guidelines */
.VLYTB-videoLinkManager p {
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
}

/* Video Links Container */
.VLYTB-video-links-container {
  margin-top: 20px;
}

.VLYTB-video-links-container h3 {
  margin-bottom: 10px;
  color: var(--LogoColor);
  font-size: 1.2rem;
  font-weight: 600;
}

.VLYTB-video-link-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 10px;
}

.VLYTB-video-link-item span {
  font-size: 14px;
  color: #333;
}

.VLYTB-video-link-item button {
  background: #e74c3c;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.VLYTB-video-link-item button:hover {
  background: #c0392b;
}

/* Responsive */
@media (max-width: 768px) {
  .VLYTB-videoLinkManager {
    margin: 20px;
    padding: 20px;
  }

  .VLYTB-add-link-container input,
  .VLYTB-add-link-container button,
  .VLYTB-logout-btn {
    font-size: 14px;
    padding: 10px 15px;
  }

  .VLYTB-video-link-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .VLYTB-video-link-item button {
    margin-top: 10px;
  }
}

.VLYTB-GuideLine {
    text-align: left;
    margin-bottom: 20px;
    color: black;
    font-size: 2rem;
    line-height: 1.6;
  }
  