.Admin-screen-a {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #212529;
    padding: 20px;
    max-width: 95%;
    margin: auto;
    background: transparent;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .Admin-screen-a h2 {
    color: white;
    margin-bottom: 20px;
    font-size: 1.2rem;
    background: radial-gradient(circle at 10.6% 22.1%, rgb(187, 90, 90) 0%, rgb(122, 21, 21) 100.7%);
    font-weight: 100;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .Admin-screen-a h3 {
    color: white;
    margin-bottom: 20px;
    font-size: 1.2rem;
    background: linear-gradient(114.3deg, rgb(231, 158, 133) 0.2%, rgb(187, 90, 90) 68.5%);
    font-weight: 100;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .Admin-patient-header {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 10px;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .WTKNpatient-header {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 10px;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  
  .Admin-patient-row {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .Admin-patient-row:last-child {
    border-bottom: none;
  }
  
  .Admin-patient-row span,
  .Admin-patient-row select {
    flex-wrap: wrap;
  }
  
  .Admin-token {
    background: linear-gradient(to right, rgb(11, 245, 33), rgb(26, 188, 156));
    padding: 0.5rem;
    color: white;
    border-radius: 5px;
  }
  
  button {
    background: linear-gradient(to right, rgb(0, 123, 255), rgb(52, 152, 219));
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s ease-in-out;
  }
  
  button:hover {
    background: linear-gradient(to right, rgb(1, 49, 30), rgb(6, 240, 95));
  }
  
  select {
    padding: px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #330101;
  }
  
  select:disabled {
    background-color: #eaeaea;
  }
  
  option {
    padding: 5px;
  }
  
  .Admin-patient-clear-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .Admin-clear-consulted,
  .Admin-clear-no-show,
  .Admin-clear-all {
    width: 20%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .Admin-clear-consulted {
    background: linear-gradient(114.3deg, rgb(11, 245, 33) 0.2%, rgb(8, 65, 91) 68.5%);
  }
  
  .Admin-clear-no-show {
    background: radial-gradient(371px at 2.9% 14.3%, rgb(255, 0, 102) 0%, rgb(80, 5, 35) 100.7%);
  }
  
  .Admin-clear-all {
    background: red;
  }
  
  .Admin-clear-consulted:hover,
  .Admin-clear-no-show:hover,
  .Admin-clear-all:hover {
    background: green;
  }
  
  /* Additional responsive design */
  @media (max-width: 768px) {
    .Admin-patient-header,
    .Admin-patient-row {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  