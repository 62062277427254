@import url('https://fonts.cdnfonts.com/css/sangira');

.app-container {
  background-color: var(--primary-color);
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--secondary-color);
}

.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping in smaller screens */
}

.app-logo {
  display: flex;
  align-items: center;
}

.app-logo img {
  height: 2.5rem;
  margin-left: 0px;
  animation: slide-in 1s ease forwards; /* Adjust duration and timing function as needed */
}

@keyframes slide-in {
  from {
    margin-left: -100px; /* Initial position off-screen */
    opacity: 0; /* Starting with opacity 0 */
  }
  to {
    margin-left: 0px; /* Ending at desired position */
    opacity: 1; /* Fully visible */
  }
}

.AppBartitle {
  font-size: 24px;
  color: var(--secondary-color);
}

.krysta {
  font-family: 'Sangira', sans-serif;
  color: white;
  font-size: 1.3rem;
  padding: 0.1rem 0.5rem;
  letter-spacing: 1.5px;
  background: darkcyan;
  margin-right: 0px; /* Adjust the value as needed */
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-decoration: none; 
  display: none;
}

.krysta::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1); /* Adjust the overlay color and opacity */
  transition: left 0.3s ease; /* Adjust the duration and timing function */
}

.krysta:hover::before {
  left: 100%;
}

.etok {
  color: black;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.1rem;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .AppBartitle {
    font-size: 22px;
  }

  .krysta {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .app-logo img {
    height: 3rem;
  }

  .AppBartitle {
    font-size: 20px;
  }

  .krysta {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .app-logo img {
    height: 3rem;
  }

  .AppBartitle {
    font-size: 18px;
  }

  .krysta {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .app-logo img {
    height: 3rem;
  }

  .AppBartitle {
    font-size: 16px;
  }

  .krysta {
    font-size: 0.9rem;
  }
}
