/* Container */
.AP-screenP-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Form Container */
.AP-form-container {
  display: flex;
  flex-direction: column;
}

/* Inputs and Selects */
.name-input-field,
.dept-input-field {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.name-input-field::placeholder,
.dept-input-field::placeholder {
  color: #999;
}

.name-input-field:focus,
.dept-input-field:focus {
  border-color: var(--LogoColor);
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}



/* Button */
.AP-token-proceed-button {
  background: var(--LogoColor);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
  text-decoration: none;
}

.AP-token-proceed-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.AP-token-proceed-button:hover:not(:disabled) {
  background: var(--color2);
}

/* Messages */
.message {
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  color: white;
}

.message.status-A,
.message.status-B,
.message.status-C,
.message.status-D,
.message.status-E {
  background-color: var(--LogoColor);
}

.message.status-F {
  background-color: #ffebee;
  border: 1px solid #f44336;
}

/* Now Serving Token */
.AP-nowServingToken {
  color: white;
  margin-top: 2rem;
}

/* Already Text */
.AP-alreadytext {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

.AP-mobile-number-lookup{
  margin-top: 1rem;
}
/* .AP-tokn-proceed-button{
  background:darkblue;
  color:white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
  text-decoration: none;

}

.AP-tokn-proceed-button:hover{
  background:darkblue;
  color:white;
  transition: background-color 0.3s;
  width: 60%;
 

} */
.noteAP{
  margin-top: 1rem;
  color: red;
}
.AvailDays{
  background-color: var(--LogoColor);
  padding: 0.5rem;
  color: white;
}

/* Responsive */
@media (max-width: 768px) {
  .AP-screenP-container {
    margin: 20px;
    padding: 20px;
  }

  .AP-token-proceed-button {
    padding: 10px 15px;
  }
}

/* Ultra Modern Animation CSS */
.selected-infoAP p {
  font-size: 1em;
  margin: 10px 0;
  padding: 10px;
  background: var(--LogoColor);
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite, slideIn 1s ease-out forwards;
  color: white;
  border-radius: 10px;
  /* box-shadow: 0 4px 15px rgba(0, 195, 195, 0.3); */
  opacity: 0;
  transform: translateY(20px);
  text-align: left;
}

@keyframes gradientShift {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.selectAPdtae{
  padding: 1.2rem;
}

.timeslots-container {
  max-width: 600px;
  margin: 0 auto;
}

.timeslots-container h2 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.timeslot-period {
  margin-bottom: 20px;
  color: var(--color2);
}

.timeslot-period h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.timeslot-period h3 {
  text-align: left;
  margin-left: 1px; /* Adjust this value as needed for desired spacing */
  margin-top: 1rem;
  font-weight: bold;
  color: var(--color2);
}

.timeslot-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.timeslot-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.timeslot-option input[type="radio"] {
  display: none;
}

.timeslot-label {
  background-color: var(--LogoColor);
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.timeslot-option input[type="radio"]:checked + .timeslot-label {
  background-color: white;
  color: var(--LogoColor);
  border: 1px solid var(--LogoColor);
}

.timeslot-option:hover .timeslot-label {
  background-color: white;
  color: var(--LogoColor);
  border: 1px solid var(--LogoColor);
}

.timeslot-label2{
color: var(--LogoColor);
cursor:none;
font-family: 'sans-serif';
}

.appointment-summary {
  background: white;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 10px;
  border: 1px solid  var(--LogoColor);
  margin-bottom: 3px;
  transition: all 0.3s ease;
}



.appointment-summary h4 {
  font-size: 1rem;
  color: var(--color2);
  margin-bottom: 10px;
  text-align: center;
}

.summary-content {
  font-size: 1rem;
  color: black;
  text-align:justify;
  /* line-height: 1.6; */
}

.summary-content p {
  margin-bottom: 2px;
}



.AP-tokn-proceed-button {
  background: var(--color2);
  color: white;
  width: 50%; /* Adjusted width for better look */
  height: 3em; /* Adjusted height for better look */
  border: var(--color2) 0.2em solid;
  border-radius: 11px;
  text-align: center;
  transition: all 0.6s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  margin: 1em auto; /* Center the button */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.AP-tokn-proceed-button:hover {
  background: var(--LogoColor);
  cursor: pointer;
}

.AP-tokn-proceed-button svg {
  width: 1.6em;
  margin-right: 0.5em; /* Adjusted margin */
  transition: all 0.6s ease;
}

.AP-tokn-proceed-button:hover svg {
  transform: translateX(5px);
}

.AP-tokn-proceed-button .text {
  margin-left: 0.5em; /* Adjusted margin */
}

.AP-tokn-proceed-button:disabled {
  cursor: not-allowed;
  background-color: #ffffff00;
  border-color: #d3d3d3;
  color: #d3d3d3;
}

.AP-tokn-proceed-button:disabled:hover svg {
  transform: none;
}

.textPCD{
  font-size: 1rem;
}


.checkkk-button {
  position: relative;
  font-family: inherit;
  font-weight: 600;
  font-size: 17px;
  border-radius: 0.8em;
  cursor: pointer;
  border: none;
  background: var(--color2);
  color: ghostwhite;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  padding: 0.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.checkkk-button:hover{
 
  background: var(--color2);
  color: ghostwhite;
}

.checkkk-button svg {
  width: 1em;
  height: 1em;
  margin-left: 0.7em;
  stroke-width: 2px;
}

.checkkk-button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
}

.checkkk-button::before,
.checkkk-button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  
}

.checkkk-button::before {
  content: "";
  background: var(--LogoColor);
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.checkkk-button:hover::before {
  transform: translate3d(100%, 0, 0);
  
}

.checkkk-button:active {
  transform: scale(0.95);
  
}

.checkkk-button:disabled {
  cursor: not-allowed;
  color: white;
}

.checkkk-button:disabled::before {
  display: none;
}


.selectAPdtae {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
}

.selectAPdtae label {
  font-size: 1rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: var(--LogoColor);
  text-align: center;
  letter-spacing: 0.03125rem;
}

.APPselex {
  width: 50%;
  max-width: 18.75rem;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid var(--LogoColor);
  border-radius: 3.125rem;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
}

.APPselex:focus {
  background-color: #ffffff;
  box-shadow: 0 0 0 2px var(--LogoColor);
}

.APPselex:hover {
  background-color: #f8f8f8;
}

@media (max-width: 48rem) {
  .selectAPdtae {
    padding: 0.9375rem;
  }
  
  .selectAPdtae label {
    font-size: 0.875rem;
  }
  
  .APPselex {
    font-size: 0.875rem;
    padding: 0.625rem 0.9375rem;
  }
}




.AvailDays77{
  font-size: 1rem;
  color:white;
  padding: 0.5rem;
  background: var(--color2);
}


.timeslot-option2 {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Adjust as needed */
  gap: 10px; /* Adjust spacing between items */
}

.timeslot-label2 {
  background-color: #f0f0f0; /* Example background color */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  flex: 1; /* Flexible width */
  max-width: 150px; /* Adjust max-width to control item width */
  margin-bottom: 0.5rem;
}

/* Example media query for responsiveness */
@media screen and (max-width: 768px) {
  .timeslot-option2 {
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  }

  .timeslot-label2 {
    max-width: 100%; /* Full width on smaller screens */
  }
}


.APcheckbuttonlink {
  display: inline-block;
  background-color: var(--color2);
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border: 2px solid var(--LogoColor);
}

.APcheckbuttonlink:hover {
  background-color: white;
  color: var(--LogoColor);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.APcheckbuttonlink:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .APcheckbuttonlink {
    display: block;
    width: 100%;
  }
}