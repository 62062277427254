/* Container */
.dLLM-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.dLLM-title {
  color: var(--LogoColor);
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}

/* Buttons */
.dLLM-reset-all-btn,
.dLLM-reset-btn,
.dLLM-photo-update-btn,
.dLLM-counter-btn,
.delete-btn {
  background: var(--LogoColor);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dLLM-reset-all-btn:hover,
.dLLM-reset-btn:hover,
.dLLM-photo-update-btn:hover,
.dLLM-counter-btn:hover,
.delete-btn:hover {
  background: var(--color2); /* Darker shade of blue on hover */
}

.dLLM-reset-all-btn:disabled,
.dLLM-reset-btn:disabled,
.dLLM-photo-update-btn:disabled,
.delete-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Doctor List */
.dLLM-doctors-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dLLM-doctor-item {
  background: #f9f9f9;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Doctor Information */
.dLLM-doctor-name,
.dLLM-doctor-room,
.dLLM-doctor-department,
.dLLM-doctor-languages,
.dLLM-doctor-consultation-fee,
.dLLM-doctor-consultation-fee-comments,
.dLLM-doctor-counter {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.dLLM-doctor-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.dLLM-photo-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.dLLM-placeholder-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* Availability and Appointment Toggles */
.dLLM-availability-toggle,
.dLLM-appointment-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.dLLM-label {
  margin-right: 10px;
}

.dLLM-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.dLLM-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dLLM-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.dLLM-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .dLLM-slider {
  background-color: var(--LogoColor);
}

input:checked + .dLLM-slider:before {
  transform: translateX(20px);
}

.dLLM-availability-message,
.dLLM-appointment-message {
  margin-left: 10px;
  font-size: 14px;
}

/* Edit Fields */
.dLLM-doctor-edit-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dLLM-doctor-edit-fields label {
  font-size: 14px;
}

.dLLM-doctor-edit-fields input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Counter Input */
.dLLM-counter-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}

/* Availability Days and Time Slots */
.dLLM-doctor-availability {
  margin-top: 20px;
}

.dLLM-availability-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.dLLM-available-days-list {
  list-style: none;
  padding: 0;
}

.dLLM-available-day-item {
  margin-bottom: 10px;
}

.dLLM-available-day {
  font-weight: 700;
}

.dLLM-time-slots-list {
  list-style: none;
  padding: 0;
}

.dLLM-time-slot-item {
  font-size: 14px;
}

.dLLM-no-availability {
  font-size: 14px;
  color: #999;
}

/* Overlay for Deletion */
.dLLM-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dLLM-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  font-size: 18px;
  color: var(--LogoColor);
}

/* Responsive */
@media (max-width: 768px) {
  .dLLM-container {
    margin: 20px;
    padding: 20px;
  }

  .dLLM-title {
    font-size: 1.3rem;
  }

  .dLLM-reset-all-btn,
  .dLLM-reset-btn,
  .dLLM-photo-update-btn,
  .dLLM-counter-btn,
  .delete-btn {
    font-size: 12px;
    padding: 8px 12px;
  }
}


.dLLM-no-doctors-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  background: var(--LogoColor);
  padding: 2rem;
  color: white;
}

.dLLM-no-doctors-message a {
  color: blue;
  text-decoration: underline;
}

.APavailtog{
  margin: 1rem;
}