/* Container for Appointment Status Check */
.Apt-status-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Title for Appointment Check */
.aptchecktitle {
  background: var(--LogoColor);
  color: white;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

/* Form */
.check-status-form {
  display: flex;
  flex-direction: column;
}

.check-status-form select {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: var(--LogoColor);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.APTinput {
  height: 3rem;
  font-size: 1rem;
}

/* Button */
.APT-status-button {
  background: var(--LogoColor);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background-color 0.3s;
  display: block; /* Ensure the button is treated as a block element */
  margin-left: auto; /* Automatically set the left margin to auto */
  margin-right: auto; /* Automatically set the right margin to auto */
  text-align: center; /* Center the text inside the button */
}

.APT-status-button:hover {
  background: var(--LogoColor);
}

.APT-status-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

/* Styling for labels */
label {
  font-weight: bold;
  color: #555;
}

/* Text input styling */
input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Container for record selection */
.record-selection {
  margin-bottom: 20px;
}

/* Styling for selection list heading */
.record-selection h3 {
  color: #333;
}

/* Styling for records list */
ul {
  list-style-type: none;
  padding: 0;
}

/* Individual record item styling */
li {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.doctor-info {
  text-align: left;
}

/* Individual record item styling */
li {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.3rem;
}

.doctor-info {
  text-align: left;
}

/* Hover effect for record items */
li:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Selected record item styling */
li.selected {
  background-color: white;
  color: black;
  border: 2px solid var(--LogoColor);
  margin-top: 0.3rem;
}


/* Styling for record details */
.record-details p {
  margin: 0; /* Remove default margin to prevent line breaks */
  white-space: nowrap; /* Prevent wrapping of record details */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display ellipsis for truncated text */
  font-size: 1rem;
} 

/* Error message styling */
.error-message {
  color: #dc3545;
  margin-bottom: 15px;
}

/* Container for status display */
.status-display {
  margin-bottom: 20px;
  background-color: white;
  color:var(--LogoColor);
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styling for status display heading */
.status-display h3 {
  color: #333;
}

/* Styling for status display message */
.status-display p {
  margin-top: 5px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .Apt-status-container {
    padding: 15px;
  }

  /* Adjust record details for mobile view */
  .record-details p {
    white-space: normal; /* Allow wrapping for better readability */
    text-overflow: initial; /* Remove ellipsis for full text display */
  }
}

/* General styles */
.APTVW-message {
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

/* Styles for all message types */
.APTVW-message--processing,
.APTVW-message--acknowledged,
.APTVW-message--waiting,
.APTVW-message--called,
.APTVW-message--completed,
.APTVW-message--missed,
.APTVW-message--unrecognized {
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: white;
  color: var(--LogoColor);
}
